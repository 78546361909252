import React, { useState } from "react";
import { Flex, Text, Divider } from "@chakra-ui/react";
import Logo from "../../assets/svg/logo.svg";
import insta from "../../assets/svg/insta.svg";
import twitter from "../../assets/svg/twitter.svg";
import facebook from "../../assets/svg/facebook.svg";


function Footer() {
  const [showFlex, setShowFlex] = useState(false);
  const [showText, setShowText] = useState(false);

  const toggleText = () => {
    setShowText(!showText);
  };

  const toggleFlex = () => {
    setShowFlex(!showFlex);
  };

  const textStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19.9px",
    color: "rgba(255, 255, 255, 1)",
  };

  const buttonStyle = {
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17.07px",
    padding: "16px 24px 16px 24px",

    background: `
      linear-gradient(62.63deg, #390EF7 13.23%, #9244F4 79.12%),
      linear-gradient(0deg, #FFFFFF, #FFFFFF)
    `,
    color: "rgba(255, 255, 255, 1)",
  };

  const bigTextStyle = {
    color: "rgba(29, 33, 48, 1)",
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38.4px",
  };

  const smallTextStyle = {
    color: "rgba(102, 112, 133, 1)",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "30px",
  };

  const serviceBigStyle = {
    color: "rgba(29, 33, 48, 1)",
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38.4px",
  };

  const serviceSmallStyle = {
    color: "rgba(102, 112, 133, 1)",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "30px",
  };

  return (
    <>
      <Flex
        color="rgba(130, 135, 151, 1)"
        fontFamily="Montserrat"
        fontSize="14px"
        fontWeight="500"
        lineHeight="22.4px"
        direction="column"
        padding={'10px'}
        justifyContent="space-evenly"
        bg="linear-gradient(89.88deg, rgba(43, 0, 236, 0.1) 0.11%, rgba(146, 68, 244, 0.1) 100.7%)"
        h="25rem"
      >
        <Flex justifyContent="space-around" align="center" direction={{base:'column',md:'row'}}>
          <Flex gap=".5rem" direction="column" >
            <img src={Logo} width="180px" height="50px" />
            <Text>Our marketing company is a results-driven agency</Text>
            <Text>dedicated to helping businesses achieve their</Text>
            <Text mb="1rem">growth objectives.</Text>
            <Flex align="center" gap="1rem">
              <img src={facebook} width="8px" height="10px" />
              <img src={twitter} width="20px" height="20px" />
              <img src={insta} width="20px" height="20px" />
            </Flex>
          </Flex>
          <Flex gap="2rem" direction="column"  >
            <Flex alignItems='flex-start'  gap="5rem" >
              <Text>Home</Text>
              <Text>Solutions</Text>
              <Text>Features</Text>
            </Flex>
            <Flex alignItems='flex-start' gap="3.8rem">
              <Text>About Us</Text>
              <Text>Integration</Text>
              <Text>Pricing</Text>
            </Flex>
          </Flex>
        </Flex>

        <Divider borderWidth="3px" color="rgba(123, 126, 150, 1)" />
        <Flex align="center" justifyContent="space-evenly">
          <Text ml="0.5rem">© Copyright 2024 SMS FLASH</Text>
          <Text mr="0.5rem">Made with love by Root&Leaves</Text>
        </Flex>
      </Flex>
    </>
  );
}

export default Footer;
