/*
 * EXPORTS
 */
export const headerStyle = {
    marginTop: '10px',
    textAlign: 'left',
    fontStyle: 'DM Sans',
    fontWeight: '400',
    fontSize: '21px',
    lineHeight: '32px',
    color: 'rgba(43, 54, 116, 0.8)'
  }
  