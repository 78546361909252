/*
 *IMPORT
 */
import React, { useState } from "react";
import {
  Box,
  Flex,
  Text,
  Button,
  Divider,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import plus from "../../assets/svg/plus.svg";
import minus from "../../assets/svg/minus.svg";

import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIos,
} from "react-icons/md";

function Faq() {
  const [showText, setShowText] = useState(false);
  const [showText1, setShowText1] = useState(false);
  const [showText2, setShowText2] = useState(false);
  const [showText3, setShowText3] = useState(false);
  const [showText4, setShowText4] = useState(false);
  const [showText5, setShowText5] = useState(false);

  const toggleText = () => {
    setShowText(!showText);
  };
  const toggleText1 = () => {
    setShowText1(!showText1);
  };
  const toggleText2 = () => {
    setShowText2(!showText2);
  };
  const toggleText3 = () => {
    setShowText3(!showText3);
  };
  const toggleText4 = () => {
    setShowText4(!showText4);
  };
  const toggleText5 = () => {
    setShowText5(!showText5);
  };

  return (
    <>
      <Text
        mt="7rem"
        textAlign="center"
        color="rgba(16, 24, 40, 1)"
        fontFamily="Inter"
        fontWeight="600"
        fontSize="40px"
        lineHeight="44px"
        letterSpacing="-0.8px"
      >
        Frequently asked questions
      </Text>
      <Text
        mt=".8rem"
        textAlign="center"
        color="rgba(61, 64, 77, 1)"
        fontFamily="Inter"
        fontWeight="400"
        fontSize="20px"
        lineHeight="30px"
      >
        Everything you need to know about the product and billing.
      </Text>
      <Flex
        direction="column"
        mt="3rem"
        alignItems="center"
        justifyContent="center"
      >
        <Flex w="70%" direction="column">
          <Flex align="center" justifyContent="space-between" >
            <Text
              color="rgba(16, 24, 40, 1)"
              fontFamily="Inter"
              fontWeight="500"
              fontSize="20px"
              lineHeight="28px"
              
            >
              What is SMS Flash?
            </Text>
            <img
              src={showText ? minus : plus}
              alt="Toggle"
              onClick={toggleText}
              style={{ cursor: "pointer" }}
            />
          </Flex>

          {showText && (
            <Flex
              color="rgba(102, 112, 133, 1)"
              fontFamily="Inter"
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
              direction="column"
            >
              <Text>
              SMS Flash is an advanced A2P messaging solution designed to facilitate high-volume
              {" "}
              </Text>
              <Text>
              reliable, and secure communication for businesses globally.
              </Text>
            </Flex>
          )}
          <Divider margin="2rem 0 2rem 0" borderWidth="2px" />
        </Flex>
        <Flex w="70%" direction="column">
          <Flex align="center" justifyContent="space-between">
            <Text
              color="rgba(16, 24, 40, 1)"
              fontFamily="Inter"
              fontWeight="500"
              fontSize="20px"
              lineHeight="28px"
            >
              How does SMS Flash ensure high delivery rates? 
            </Text>
            <img
              src={showText1 ? minus : plus}
              alt="Toggle"
              onClick={toggleText1}
              style={{ cursor: "pointer" }}
            />
          </Flex>

          {showText1 && (
            <Flex
              color="rgba(102, 112, 133, 1)"
              fontFamily="Inter"
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
              direction="column"
            >
              <Text>
              SMS Flash leverages direct connections with operators and advanced routing capabilitie{" "}
              </Text>
              <Text>
              to ensure prompt and reliable message delivery.

              </Text>
            </Flex>
          )}
          <Divider margin="2rem 0 2rem 0" borderWidth="2px" />
        </Flex>
        <Flex w="70%" direction="column">
          <Flex align="center" justifyContent="space-between">
            <Text
              color="rgba(16, 24, 40, 1)"
              fontFamily="Inter"
              fontWeight="500"
              fontSize="20px"
              lineHeight="28px"
            >
             Can SMS Flash handle large volumes of messages?
            </Text>
            <img
              src={showText2 ? minus : plus}
              alt="Toggle"
              onClick={toggleText2}
              style={{ cursor: "pointer" }}
            />
          </Flex>

          {showText2 && (
            <Flex
              color="rgba(102, 112, 133, 1)"
              fontFamily="Inter"
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
              direction="column"
            >
              <Text>
              Yes, SMS Flash is designed to scale efficiently, accommodating both small and large volumes{" "}
              </Text>
              <Text>
              of messages without compromising quality.

              </Text>
            </Flex>
          )}
          <Divider margin="2rem 0 2rem 0" borderWidth="2px" />
        </Flex>
        <Flex w="70%" direction="column">
          <Flex align="center" justifyContent="space-between">
            <Text
              color="rgba(16, 24, 40, 1)"
              fontFamily="Inter"
              fontWeight="500"
              fontSize="20px"
              lineHeight="28px"
            >
               Is the platform secure?
            </Text>
            <img
              src={showText3 ? minus : plus}
              alt="Toggle"
              onClick={toggleText3}
              style={{ cursor: "pointer" }}
            />
          </Flex>

          {showText3 && (
            <Flex
              color="rgba(102, 112, 133, 1)"
              fontFamily="Inter"
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
              direction="column"
            >
              <Text>
              Absolutely. SMS Flash prioritizes data security with robust security features and compliance with industry standards.
              {" "}
              </Text>
              {/* <Text>
                30-minute onboarding call to get you up and running as soon as
                possible.
              </Text> */}
            </Flex>
          )}
          <Divider margin="2rem 0 2rem 0" borderWidth="2px" />
        </Flex>
        <Flex w="70%" direction="column">
          <Flex align="center" justifyContent="space-between">
            <Text
              color="rgba(16, 24, 40, 1)"
              fontFamily="Inter"
              fontWeight="500"
              fontSize="20px"
              lineHeight="28px"
            >
            Does SMS Flash provide analytics?
            </Text>
            <img
              src={showText4 ? minus : plus}
              alt="Toggle"
              onClick={toggleText4}
              style={{ cursor: "pointer" }}
            />
          </Flex>

          {showText4 && (
            <Flex
              color="rgba(102, 112, 133, 1)"
              fontFamily="Inter"
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
              direction="column"
            >
              <Text>
              Yes, SMS Flash offers real-time analytics to help businesses track and optimize their messaging campaigns effectively{" "}
              </Text>
            </Flex>
          )}
          <Divider margin="2rem 0 2rem 0" borderWidth="2px" />
        </Flex>
        <Flex w="70%" direction="column">
          <Flex align="center" justifyContent="space-between">
            <Text
              color="rgba(16, 24, 40, 1)"
              fontFamily="Inter"
              fontWeight="500"
              fontSize="20px"
              lineHeight="28px"
            >
               Can I send messages globally with SMS Flash?
            </Text>
            <img
              src={showText5 ? minus : plus}
              alt="Toggle"
              onClick={toggleText5}
              style={{ cursor: "pointer" }}
            />
          </Flex>

          {showText5 && (
            <Flex
              color="rgba(102, 112, 133, 1)"
              fontFamily="Inter"
              fontWeight="400"
              fontSize="16px"
              lineHeight="24px"
              direction="column"
            >
              <Text>
              Yes, SMS Flash provides instant global reach, allowing businesses to send messages worldwide.{" "}
              </Text>
            </Flex>
          )}
          <Divider margin="2rem 0 2rem 0" borderWidth="2px" />
        </Flex>
      </Flex>
    </>
  );
}

export default Faq;
