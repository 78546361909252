/*
 * IMPORT
 */
import React from "react"; // NPM: React package.
import { Toaster } from 'react-hot-toast' // Npm: React hot toast.
import { useTheme } from "@chakra-ui/react"; // NPM: Styled components.


/*
 * COMPONENTS
 */
import LandingPage from "components/landingpage/LandingPage";

/*
 * OJBECT
 */
const Index = () => {
  const _themeRef = useTheme()

  // Return
  return( <>
    <Toaster
     position='bottom-left'
      reverseOrder={false}
      containerClassName='toaster'
      toastOptions={{
      'duration': 3000,
         'style': {
            'background': _themeRef.colors.purple[700],
            'color': _themeRef.colors.white
          }
                    }} />
    <LandingPage />
  </>);
};

/*
 * EXPORT
 */
export default Index;
