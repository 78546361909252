/*
 * IMPORT
 */
import React from "react"; // Npm: React.
import ReactDOM from "react-dom"; // Npm: React dom.
import App from "index.app.js"; // App component.

import { ChakraProvider } from "@chakra-ui/react"; // Npm: Chakra UI provider.


/*
 * RENDER
 */
const Root = () => {
  return (
    <ChakraProvider >
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ChakraProvider>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
