import React, { useState } from "react";
import { Box, Flex, Text, Button, Divider } from "@chakra-ui/react";
import bulk from "../../assets/svg/bulk.svg";
import reseller from "../../assets/svg/reseller.svg";

import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIos,
} from "react-icons/md";

function ExploreServices() {
  const [showFlex, setShowFlex] = useState(false);
  const [showText, setShowText] = useState(false);

  const toggleText = () => {
    setShowText(!showText);
  };

  const toggleFlex = () => {
    setShowFlex(!showFlex);
  };

  const textStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19.9px",
    color: "rgba(255, 255, 255, 1)",
  };

  const buttonStyle = {
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17.07px",
    padding: "16px 24px 16px 24px",

    background: `
      linear-gradient(62.63deg, #390EF7 13.23%, #9244F4 79.12%),
      linear-gradient(0deg, #FFFFFF, #FFFFFF)
    `,
    color: "rgba(255, 255, 255, 1)",
  };

  const bigTextStyle = {
    color: "rgba(29, 33, 48, 1)",
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38.4px",
  };

  const smallTextStyle = {
    color: "rgba(102, 112, 133, 1)",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "30px",
  };

  const serviceBigStyle = {
    color: "rgba(29, 33, 48, 1)",
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38.4px",
  };

  const serviceSmallStyle = {
    color: "rgba(102, 112, 133, 1)",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "30px",
  };

  return (
    <>
      <Text
        mt="2rem"
        textAlign="center"
        color="rgba(38, 50, 56, 1)"
        fontFamily="Roboto"
        fontWeight="700"
        fontSize="37px"
        lineHeight="46.88px"
      >
        Telecom Excellence: Explore Our Services
      </Text>
      <Flex
        position="relative"
        justifyContent="space-evenly"
        mt="3rem"
        align="center"
        padding={'2rem'}
        direction={{base:'column',lg:'row'}}
        
      >
        <Flex gap="1rem" textAlign="left" direction="column">
          <Text
            bg="linear-gradient(59.58deg, #390EF7 13.89%, #9244F4 82.14%)"
            bgClip="text"
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="700"
            lineHeight="28.8px"
          >
            Bulk Messaging
          </Text>
          <Flex style={serviceBigStyle} direction="column">
            <Text>Empowering Your Messages</Text>
            <Text>with Bulk Messaging</Text>
          </Flex>

          <Flex style={serviceSmallStyle} direction="column">
            <Text>Efficiently send large volumes of messages to targeted</Text>
            <Text>audiences, making communication with customers,</Text>
            <Text>clients, or employees seamless and quick.</Text>
          </Flex>
        </Flex>
        <img src={bulk} style={{padding:'20px'}}/>
        <Flex
          left="0"
          position="absolute"
          w="400px"
          h="400px"
          opacity="0.5"
          
          filter="blur(150px)"
          bg="radial-gradient(2762.36% 537.65% at -49.5% -250%, #D798E1 17.55%, #AED3FF 49.89%, #C9D4EF 56.53%, #CACFFA 65.69%)"
        ></Flex>
      </Flex>
      <Flex justifyContent="space-evenly" mt="3rem" align="center" padding={'2rem'}
          direction={{base:'column-reverse',lg:'row'}}>
        <img src={reseller} style={{padding:'20px'}}/>
        <Flex gap="1rem" textAlign="left" direction="column">
          <Text
            bg="linear-gradient(59.58deg, #390EF7 13.89%, #9244F4 82.14%)"
            bgClip="text"
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="700"
            lineHeight="28.8px"
          >
            Reseller Panel
          </Text>
          <Flex style={serviceBigStyle} direction="column">
            <Text>Navigate the Reseller</Text>
            <Text>Panel with Ease</Text>
          </Flex>

          <Flex style={serviceSmallStyle} direction="column">
            <Text>Empower businesses or individuals to become resellers,</Text>
            <Text>enabling them to distribute SMS services under their</Text>
            <Text>brand, enhancing your product's reach and market</Text>
            <Text>penetration.</Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex
        position="relative"
        justifyContent="space-evenly"
        mt="3rem"
        align="center"
        padding={'2rem'}
        direction={{base:'column',lg:'row'}}
        
      >
        <Flex gap="1rem" textAlign="left" direction="column" >
          <Text
            bg="linear-gradient(59.58deg, #390EF7 13.89%, #9244F4 82.14%)"
            bgClip="text"
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="700"
            lineHeight="28.8px"
          >
            Retail Clients Panel
          </Text>
          <Flex style={serviceBigStyle} direction="column">
            <Text>The Retail Clients Panel for</Text>
            <Text>Easy Access</Text>
          </Flex>

          <Flex style={serviceSmallStyle} direction="column">
            <Text>
              Provide a user-friendly interface for retail clients, allowing
            </Text>
            <Text>them to easily manage their messaging campaigns,</Text>
            <Text>monitor message delivery, and track responses in real</Text>
            <Text>time.</Text>
          </Flex>
        </Flex>
        <img src={bulk} style={{padding:'20px'}}/>
        <Flex
          right="0"
          position="absolute"
          w="400px"
          h="400px"
          opacity="0.5"
          filter="blur(150px)"
          bg="radial-gradient(2762.36% 537.65% at -49.5% -250%, #D798E1 17.55%, #AED3FF 49.89%, #C9D4EF 56.53%, #CACFFA 65.69%)"
        ></Flex>
      </Flex>
      <Flex justifyContent="space-evenly" mt="3rem" align="center" padding={'2rem'}
        direction={{base:'column-reverse',lg:'row'}}>
        <img src={bulk} style={{padding:'20px'}}/>
        <Flex gap="1rem" textAlign="left" direction="column">
          <Text
            bg="linear-gradient(59.58deg, #390EF7 13.89%, #9244F4 82.14%)"
            bgClip="text"
            fontFamily="Inter"
            fontSize="24px"
            fontWeight="700"
            lineHeight="28.8px"
          >
            Campaigning Services
          </Text>
          <Flex style={serviceBigStyle} direction="column">
            <Text>Unleash the Power of Our</Text>
            <Text>Campaigning Services</Text>
          </Flex>

          <Flex style={serviceSmallStyle} direction="column">
            <Text>
              Offer specialized campaigning services, enabling businesses to
            </Text>
            <Text>
              create and execute targeted SMS campaigns for marketing,
            </Text>
            <Text>
              promotions, surveys, or announcements, ensuring a high level of
            </Text>
            <Text>engagement with their audience</Text>
          </Flex>
        </Flex>
      </Flex>

      {showFlex && (
        <Flex direction="column">
          <Flex
            position="relative"
            justifyContent="space-evenly"
            mt="3rem"
            align="center"
          >
            <Flex gap="1rem" textAlign="left" direction="column">
              <Text
                bg="linear-gradient(59.58deg, #390EF7 13.89%, #9244F4 82.14%)"
                bgClip="text"
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="700"
                lineHeight="28.8px"
              >
                A2P Messaging Services
              </Text>
              <Flex style={serviceBigStyle} direction="column">
                <Text>A2P Messaging</Text>
                <Text>Services Simplified</Text>
              </Flex>

              <Flex style={serviceSmallStyle} direction="column">
                <Text>Facilitate Application-to-Person (A2P) messaging,</Text>
                <Text>enabling businesses to send transactional messages</Text>
                <Text>such as alerts, notifications, and OTPs (One-Time</Text>
                <Text>Passwords) securely and reliably</Text>
              </Flex>
            </Flex>
            <img src={bulk} />
            <Flex
              left="0"
              position="absolute"
              w="400px"
              h="400px"
              opacity="0.5"
              filter="blur(150px)"
              bg="radial-gradient(2762.36% 537.65% at -49.5% -250%, #D798E1 17.55%, #AED3FF 49.89%, #C9D4EF 56.53%, #CACFFA 65.69%)"
            ></Flex>
          </Flex>
          <Flex justifyContent="space-evenly" mt="3rem" align="center">
            <img src={reseller} style={{padding:'20px'}}/>
            <Flex gap="1rem" textAlign="left" direction="column">
              <Text
                bg="linear-gradient(59.58deg, #390EF7 13.89%, #9244F4 82.14%)"
                bgClip="text"
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="700"
                lineHeight="28.8px"
              >
                Delivery Reports and Analytics
              </Text>
              <Flex style={serviceBigStyle} direction="column" padding={'2rem'}>
                <Text>Unveiling Delivery Reports</Text>
                <Text>and Analytics</Text>
              </Flex>

              <Flex style={serviceSmallStyle} direction="column">
                <Text>
                  Provide detailed delivery reports and analytics to clients,
                </Text>
                <Text>
                  offering insights into message delivery rates, open rates,
                </Text>
                <Text>
                  click-through rates, and customer responses, allowing
                </Text>
                <Text>businesses to assess the effectiveness of their</Text>
                <Text>campaigns.</Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            position="relative"
            justifyContent="space-evenly"
            mt="8rem"
            align="center"
          >
            <Flex gap="1rem" textAlign="left" direction="column">
              <Text
                bg="linear-gradient(59.58deg, #390EF7 13.89%, #9244F4 82.14%)"
                bgClip="text"
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="700"
                lineHeight="28.8px"
              >
                Personalization and Customization
              </Text>
              <Flex style={serviceBigStyle} direction="column">
                <Text>The Art of Personalization and</Text>
                <Text>ECustomization</Text>
              </Flex>

              <Flex style={serviceSmallStyle} direction="column">
                <Text>
                  Allow businesses to personalize messages by including
                </Text>
                <Text>
                  recipient names or other relevant details, enhancing
                </Text>
                <Text>
                  customer engagement and the likelihood of a positive
                </Text>
                <Text>response.</Text>
              </Flex>
            </Flex>
            <img src={bulk} style={{padding:'20px'}}/>
            <Flex
              right="0"
              position="absolute"
              w="400px"
              h="400px"
              opacity="0.5"
              filter="blur(150px)"
              bg="radial-gradient(2762.36% 537.65% at -49.5% -250%, #D798E1 17.55%, #AED3FF 49.89%, #C9D4EF 56.53%, #CACFFA 65.69%)"
            ></Flex>
          </Flex>
          <Flex justifyContent="space-evenly" mt="3rem" align="center">
            <img src={bulk} style={{padding:'20px'}}/>
            <Flex gap="1rem" textAlign="left" direction="column">
              <Text
                bg="linear-gradient(59.58deg, #390EF7 13.89%, #9244F4 82.14%)"
                bgClip="text"
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="700"
                lineHeight="28.8px"
              >
                Opt-In and Opt-Out Management:
              </Text>
              <Flex style={serviceBigStyle} direction="column">
                <Text>Opt-In and Opt-Out Management </Text>
                <Text>Made Simple</Text>
              </Flex>

              <Flex style={serviceSmallStyle} direction="column">
                <Text>
                  Assist businesses in managing customer preferences by
                </Text>
                <Text>
                  providing opt-in and opt-out management services, ensuring
                </Text>
                <Text>
                  compliance with regulations and promoting a positive user
                </Text>
                <Text>experience.</Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            position="relative"
            justifyContent="space-evenly"
            mt="5rem"
            align="center"
          >
            <Flex gap="1rem" textAlign="left" direction="column">
              <Text
                bg="linear-gradient(59.58deg, #390EF7 13.89%, #9244F4 82.14%)"
                bgClip="text"
                fontFamily="Inter"
                fontSize="24px"
                fontWeight="700"
                lineHeight="28.8px"
              >
                Integration Support
              </Text>
              <Flex style={serviceBigStyle} direction="column">
                <Text>Your Experience with</Text>
                <Text>Integration Support</Text>
              </Flex>

              <Flex style={serviceSmallStyle} direction="column">
                <Text>
                  Provide support for integrating SMS Flash with other
                </Text>
                <Text>systems and platforms, enabling seamless</Text>
                <Text>clients, or employees seamless and quick.</Text>
              </Flex>
            </Flex>
            <img src={bulk} style={{padding:'20px'}}/>
            <Flex
              left="0"
              position="absolute"
              w="400px"
              h="400px"
              opacity="0.5"
              filter="blur(150px)"
              bg="radial-gradient(2762.36% 537.65% at -49.5% -250%, #D798E1 17.55%, #AED3FF 49.89%, #C9D4EF 56.53%, #CACFFA 65.69%)"
            ></Flex>
          </Flex>
        </Flex>
      )}

      <Flex mt="5rem" justify="center">
        <Button
          w="127px"
          h="49px"
          bg="linear-gradient(62.63deg, #390EF7 13.23%, #9244F4 79.12%),
            linear-gradient(0deg, #FFFFFF, #FFFFFF)"
          borderRadius="8px"
          padding="16px, 24px, 16px, 24px"
          color="rgba(255, 255, 255, 1)"
          fontFamily="Montserrat"
          fontSize="14px"
          fontWeight="600"
          lineHeight="17.07px"
          _hover={{
            bg: "linear-gradient(62.63deg, #390EF7 13.23%, #9244F4 79.12%),linear-gradient(0deg, #FFFFFF, #FFFFFF)",
          }}
          onClick={toggleFlex}
          gap=".5rem"
        >
          {showFlex ? "View Less" : "View All"}
          {showFlex ? (
            <MdOutlineArrowBackIos size="" />
          ) : (
            <MdOutlineArrowForwardIos size="" />
          )}
        </Button>
      </Flex>
    </>
  );
}

export default ExploreServices;
