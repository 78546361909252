import React, { useState } from "react";
import { Box, Flex, Text, Button, Divider } from "@chakra-ui/react";
import dummy from "../../assets/svg/dummy.svg";
import left from "../../assets/svg/left.svg";
import right from "../../assets/svg/right.svg";



const slidesData = [
  {
    text: [
      "Excellent Service",
      "I've been using SMSFlash for over a year now and I'm extremely satisfied with their service.",
      "The platform is user-friendly, reliable, and cost-effective. Highly recommended!",
    ],
    author: " ⁠Priya Sharma",
    image: dummy, // Add the image path here,
    role: " Marketing Manager",
  },
  {
    text: [
      "Seamless Communication",
      "SMSFlash has been a game-changer for our business. We've been able to reach our",
      "customers more effectively and efficiently. The team is responsive and always willing to help.",
    ],
    author: "⁠Fatima Khan",
    image: dummy, // Add the image path here,
    role: "Sales Director",
  },
  {
    text: [
      "Efficient and Reliable.",
      "I love how easy it is to send SMS messages with SMSFlash. The platform is efficient and ",
      "reliable, and I've never had any issues with message delivery. Definitely worth trying!",
    ],
    author: " ⁠Luca Rossi",
    image: dummy, // Add the image path here,
    role: "Entrepreneur",
  },

  {
    text: [
      "Highly Recommended",
      "I've used several SMS platforms in the past, but SMSFlash stands out as the best. The ",
      "features, reliability, and customer support are unmatched. I highly recommend giving them a try",
    ],
    author: " ⁠Aisha Rahman,",
    image: dummy, // Add the image path here,
    role: "Event Planner",
  }, {
    text: [
      "Excellent Customer Support.",
      "The customer support team at SMSFlash is top-notch. They're always available to answer  ",
      "questions and provide guidance. I've had a great experience working with them.!",
    ],
    author: "⁠Mei Li",
    image: dummy, // Add the image path here,
    role: " Non-Profit Coordinator",
  },
];

function Review() {
  const [showFlex, setShowFlex] = useState(false);
  const [showText, setShowText] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleSlideChange = (newSlide) => {
    if (newSlide >= 0 && newSlide < slidesData.length) {
      setCurrentSlide(newSlide);
    }
  };

  const toggleText = () => {
    setShowText(!showText);
  };

  const toggleFlex = () => {
    setShowFlex(!showFlex);
  };

  const textStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19.9px",
    color: "rgba(255, 255, 255, 1)",
  };

  const buttonStyle = {
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17.07px",
    padding: "16px 24px 16px 24px",

    background: `
      linear-gradient(62.63deg, #390EF7 13.23%, #9244F4 79.12%),
      linear-gradient(0deg, #FFFFFF, #FFFFFF)
    `,
    color: "rgba(255, 255, 255, 1)",
  };

  const bigTextStyle = {
    color: "rgba(29, 33, 48, 1)",
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38.4px",
  };

  const smallTextStyle = {
    color: "rgba(102, 112, 133, 1)",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "30px",
  };

  const serviceBigStyle = {
    color: "rgba(29, 33, 48, 1)",
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38.4px",
  };

  const serviceSmallStyle = {
    color: "rgba(102, 112, 133, 1)",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "30px",
  };

  return (
    <>
      <Text
        mt="5rem"
        textAlign="center"
        color="rgba(38, 50, 56, 1)"
        fontFamily="Roboto"
        fontWeight="700"
        fontSize="37px"
        lineHeight="46.88px"
        padding={'0.5rem'}
      >
        Here’s What Clients Says about️️ SmsFlash
      </Text>
      <Flex mt="2rem" justifyContent="center">
        <Flex
          gap="1rem"
          direction="column"
          w="80%"
          borderRadius="48px"
          h="480px"
          bg="linear-gradient(148.13deg, rgba(57, 14, 247, 0.08) 16.69%, rgba(146, 68, 244, 0.08) 84.78%)"
        >
          <Flex
            mt="5rem"
            direction="column"
            textAlign="center"
            fontFamily="Palanquin"
            fontWeight="400"
            fontSize="18px"
            lineHeight="28.8px"
            color="rgba(38, 50, 56, 1)"
          >
            {slidesData[currentSlide].text.map((textBlock, index) => (
              <Text key={index}>{textBlock}</Text>
            ))}
            {/* <Text>
              We saw a transformative shift in our marketing reports and are
              able to strategize even better than before. All
            </Text>
            <Text>
              thanks to the SMSFlash tool for delivering real-time insights that
              empowered our data-driven decisions eventually
            </Text>
            <Text>resulting in substantial ROI growth.</Text> */}
          </Flex>
          <Flex
            gap=".5rem"
            fontFamily="Montserrat"
            fontSize="16px"
            lineHeight="19.5px"
            color="rgba(38, 50, 56, 1)"
            direction="column"
            alignItems="center"
          >
            <Flex w="64px" h="64px" borderRadius="50%">
              <img src={slidesData[currentSlide].image} alt="Author" />
            </Flex>
            <Text fontWeight="700">{slidesData[currentSlide].author}</Text>
            <Text fontWeight="500">{slidesData[currentSlide].role}</Text>
          </Flex>

          <Flex mt="3rem" justifyContent="center" align="center" gap="1rem">
            <img
              src={left}
              alt="Previous"
              style={{ cursor: "pointer" }}
              onClick={() => handleSlideChange(currentSlide - 1)}
            />
            <Text>
              {currentSlide + 1}/{slidesData.length}
            </Text>
            <img
              src={right}
              alt="Next"
              style={{ cursor: "pointer" }}
              onClick={() => handleSlideChange(currentSlide + 1)}
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default Review;
