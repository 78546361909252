import React, { useState } from "react";
import { toast } from 'react-hot-toast' // Npm: React hot toast.
import { Box,Spinner, Flex, Text, Button ,useDisclosure ,Modal, FormControl,FormLabel,Input,ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody,ModalCloseButton} from "@chakra-ui/react";
import { IoIosSave } from 'react-icons/io' // Npm: React icons.
import emailjs from 'emailjs-com';
import { FiPlayCircle } from "react-icons/fi";
import ss from "../../assets/svg/ss.svg";
import Logo from "../../assets/svg/logo.svg";
import message from "../../assets/svg/message.svg";
import thunderbolt from "../../assets/svg/thunderbolt.svg";
import happyface from "../../assets/svg/happyface.svg";
import bar from "../../assets/svg/bar.svg";
import command from "../../assets/svg/command.svg";
import chart from "../../assets/svg/chart.svg";
import groupIcon from "../../assets/svg/groupIcon.svg";

import ExploreServices from "./ExploreServices";
import Review from "./Review";
import Faq from "./Faq";
import Footer from "./Footer";
import { headerStyle } from "./index.style";

function LandingPage() {
  const [showFlex, setShowFlex] = useState(false);
  const [showText, setShowText] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [formData , setFormData] = useState({
    firstName:'',
    lastName:'',
    email:'',
    mobileNumber:''
  })  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };
  const toggleText = () => {
    setShowText(!showText);
  };

  const toggleFlex = () => {
    setShowFlex(!showFlex);
  };

  const textStyle = {
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: "500",
    lineHeight: "19.9px",
    color: "rgba(255, 255, 255, 1)",
  };

  const buttonStyle = {
    borderRadius: "8px",
    fontFamily: "Montserrat",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "17.07px",
    padding: "16px 24px 16px 24px",

    background: `
      linear-gradient(62.63deg, #390EF7 13.23%, #9244F4 79.12%),
      linear-gradient(0deg, #FFFFFF, #FFFFFF)
    `,
    color: "rgba(255, 255, 255, 1)",
  };

  const bigTextStyle = {
    color: "rgba(29, 33, 48, 1)",
    fontFamily: "Roboto",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38.4px",
  };

  const smallTextStyle = {
    color: "rgba(102, 112, 133, 1)",
    fontFamily: "Roboto",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "30px",
  };

  const serviceBigStyle = {
    color: "rgba(29, 33, 48, 1)",
    fontFamily: "Inter",
    fontWeight: "700",
    fontSize: "32px",
    lineHeight: "38.4px",
  };

  const serviceSmallStyle = {
    color: "rgba(102, 112, 133, 1)",
    fontFamily: "Inter",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "30px",
  };

  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      // Email to the user
      const userTemplateParams = {
        from_name: 'smsflash',
        to_name: formData.firstName, 
        user_email: formData.email,
        message: 'Thank you for booking a demo! We have received your request and will get back to you soon.'
      };
  
      // Email to you (admin)
      const adminTemplateParams = {
        from_name: `${formData.firstName} ${formData.lastName}`,
        user_email: formData.email,
        mobile_number: formData.mobileNumber,
        message: 'A new demo has been booked by the user.'
      };
  
      // Send confirmation email to user
      const userResponse = await emailjs.send(
        'service_gh8lf0m',
        'template_a4ya0rn', 
        userTemplateParams,
        'GalPKFYeUa2tgGCaE'
      );
  
      // Send admin notification email
      const adminResponse = await emailjs.send(
        'template_0lpdsxn',
        'template_a4ya0rn', 
        adminTemplateParams,
        'GalPKFYeUa2tgGCaE' 
      );

      // Send a second admin notification email
      const adminResponse2 = await emailjs.send(
        'template_0lpdsxn', 
        'template_a4ya0rn',
        { ...adminTemplateParams, to_name: 'info@smsflash.in' }, 
        'GalPKFYeUa2tgGCaE' 
      );
  
      if (userResponse.status === 200 && adminResponse.status === 200 && adminResponse2.status === 200) { // Check if all emails were sent successfully
        toast.success('Demo booked successfully. You will receive a confirmation email.');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          mobileNumber: ''
        }); // Reset form data
        onClose();
      } else {
        toast.error('There was an issue with the booking. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      toast.error('An error occurred. Please check your email and try again.');
    }
  };
  
  
  console.log(formData , 'formData')
  

  console.log(formData , handleSubmit , 'formData') 
  return (
    <Box position="relative" p="0" bg="rgba(255, 255, 255, 1)" w='100vw' overflow='hidden'>
      <Flex padding="2rem" justifyContent="space-between"  direction={{base:'column',sm:'row'}}>
        <img style={{ cursor: "pointer" }} src={Logo} alt="" />

        <Button style={buttonStyle}  onClick={onOpen} w="144px"  h="49px">
          Book a Demo
        </Button>
        <Modal
        isOpen={isOpen}
        onClose={onClose}
        bg='blackAlpha.300'
      > <ModalOverlay />
       <ModalContent borderRadius='10px'>
          <ModalHeader  style={headerStyle}>Book A Demo</ModalHeader>
          <ModalCloseButton size='lg'
          mt='15px'
          mr='10px'
          onClick={onClose}
          color='rgba(238, 93, 80, 1)'
          background='rgba(0, 0, 0, 0.08)' />
          <ModalBody pb={6} borderRadius={8}>
            <Flex flexDir='column'  justifyContent='space-between'>
            <Flex flexDirection='row'  gap='10px'>
            <FormControl mt={4}> <FormLabel >First name</FormLabel> <Input borderRadius='10px' name="firstName" value={formData.firstName} onChange={handleChange}  placeholder='First name' /></FormControl> 
            <FormControl mt={4}><FormLabel>Last name</FormLabel> <Input  borderRadius='10px'  name="lastName" value={formData.lastName} onChange={handleChange} placeholder='Last name' /></FormControl>
            </Flex> 
            <Flex flexDirection='column'>
            <FormControl mt={4} ><FormLabel>Email</FormLabel> <Input  borderRadius='10px'   name="email" value={formData.email} onChange={handleChange} placeholder='Email' /></FormControl>
            <FormControl mt={4}><FormLabel>Mobile Number</FormLabel> <Input   borderRadius='10px'  name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} placeholder='919999999' /></FormControl>
            </Flex>
            </Flex>
             </ModalBody>

          <ModalFooter> 
            <Button   
            type='submit'  
            _hover={{ 'bg': 'brand.400' }}
            variant='basic'
            _active={{ 'bg': 'brand.400' }}
            minW='150px'  bg="#7C3AF5" color='white' onClick={handleSubmit} 
            fontSize='18px'
            isLoading={isSubmitting}>
            { (
    <>
      Submit
      <IoIosSave size={24} style={{ marginLeft: '10px' }} />
    </>
  )}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </Flex>{" "}
      <Flex mt="5rem" direction="column" gap="1rem" align="center"> 

        <Flex
          textAlign="center"
          padding={'1rem'}
          direction="column"
          color="rgba(16, 24, 40, 1)"
          fontFamily="Inter"
          fontSize="60px"
          fontWeight="600"
          letterSpacing="-1.2px"
          lineHeight="72px"
        >
          <Text fontSize={{ base: "50px", md: "55px" }}>Unlock Messaging Potential with </Text>
          <Text fontSize={{ base: "50px", md: "55px" }}>SMS Flash</Text>
        </Flex>

        <Flex
          left="0"
          position="absolute"
          w="400px"
          h="400px"
          opacity="0.5"
          filter="blur(150px)"
          bg="radial-gradient(2762.36% 537.65% at -49.5% -250%, #D798E1 17.55%, #AED3FF 49.89%, #C9D4EF 56.53%, #CACFFA 65.69%)"
        ></Flex>
        <Flex
          right="0"
          position="absolute"
          w="400px"
          h="400px"
          opacity="0.5"
          filter="blur(150px)"
          bg="radial-gradient(2762.36% 537.65% at -49.5% -250%, #D798E1 17.55%, #AED3FF 49.89%, #C9D4EF 56.53%, #CACFFA 65.69%)"
        ></Flex>
      </Flex>
      <Flex
        textAlign="center"
        direction="column"
        color="rgba(102, 112, 133, 1)"
        fontFamily="Inter"
        fontSize="20px"
        fontWeight="400"
        lineHeight="30px"
      >
        <Text padding={'0.5rem'}>
          Elevate your A2P Messaging with SMS Flash, the cutting-edge SMPP
          Switch that offers Content-Based Routing,
        </Text>
        <Text>Bulk Scheduling, AI-Powered Security, and more.</Text>
      </Flex>
      <Flex gap="1rem" mt="2rem" align="center" justify="center">
        <Button
          fontFamily="Inter"
          fontWeight="600"
          fontSize="18px"
          lineHeight="28px"
          color="rgba(52, 64, 84, 1)"
          bg="rgba(255, 255, 255, 1)"
          border="1px solid rgba(208, 213, 221, 1)"
          borderRadius="8px"
          h="60px"
          w="143px"
          gap=".5rem"
        >
          <FiPlayCircle size="20px" />
          Demo
        </Button>
        <Button
          border="1px solid rgba(127, 86, 217, 1)"
          borderRadius="8px"
          fontFamily="Inter"
          fontSize="18px"
          fontWeight="600"
          lineHeight="28px"
          color="rgba(255, 255, 255, 1)"
          bg="linear-gradient(82.65deg, #390EF7 2.87%, #9244F4 97.54%)"
          w="154px"
          h="60px"
          _hover={{
            bg: "linear-gradient(82.65deg, #390EF7 2.87%, #9244F4 97.54%)",
          }}
        >
          Contact Us
        </Button>
      </Flex>
      <Flex mt="7rem" justifyContent="center">
        <Flex
          borderTop="10px solid black"
          borderLeft="10px solid black"
          borderRight="10px solid black"
          borderRadius="16px"
        >
          <img src={ss} />
        </Flex>
      </Flex>
      <Flex
        direction="column"
        bg="linear-gradient(265.57deg, #DBD3FF 0%, rgba(216, 227, 252, 0.41) 100%)"
        h="407px"
        align="center"
        justify="center"
        gap="1rem"
      >
        <Text
          bg="linear-gradient(59.45deg, #390EF7 13.91%, #9244F4 82.08%)"
          bgClip="text"
          fontFamily="Inter"
          fontWeight="600"
          fontSize="20px"
          lineHeight="28.8px"
        >
          About Us
        </Text>
        <Text
          color="rgba(29, 33, 48, 1)"
          fontFamily="Inter"
          fontWeight="600"
          fontSize="38px"
          lineHeight="45.6px"
        >
          Who We Are
        </Text>
        <Flex textAlign="center" direction="column">
          <Text
            color="rgba(102, 112, 133, 1)"
            fontFamily="Inter"
            fontSize="18px"
            fontWeight="400"
            lineHeight="30px"
            marginLeft='40px'
            padding={'0.25rem'}
            textAlign='left'

            
          >
            SMSFlash is a platform designed to provide a seamless and efficient way for users to   <br/>
            send SMS messages. Our mission is to empower
            individuals and businesses with a the <br/>  
            reliable and cost-effective means of communication. We strive to maintain the highest
            <br/>
             standards of quality and , customer satisfaction ensuring that our services meet <br/>
             and customer satisfaction, ensuring that our services meet the evolving needs of our users.

          </Text>
        </Flex>
      </Flex>
      <Flex mt="5rem" direction="column">
        <Flex
          gap="1rem"
          textAlign="center"
          justifyContent="center"
          direction="column"
        >
          <Text
            color="rgba(105, 65, 198, 1)"
            fontFamily="Inter"
            fontWeight="600"
            fontSize="24px"
            lineHeight="24px"
          >
            Features
          </Text>
          <Text
            color="rgba(16, 24, 40, 1)"
            fontFamily="Inter"
            fontSize="36px"
            fontWeight="600"
            lineHeight="44px"
            letterSpacing="-0.72px"
          >
            Analytics that feels like it’s from the future
          </Text>
          <Flex direction="column">
            <Text
              color="rgba(102, 112, 133, 1)"
              fontFamily="Inter"
              fontWeight="400"
              fontSize="20px"
              lineHeight="30px"
            >
              Powerful, self-serve product and growth analytics to help you
              convert, engage,
            </Text>
            <Text
              color="rgba(102, 112, 133, 1)"
              fontFamily="Inter"
              fontWeight="400"
              fontSize="20px"
              lineHeight="30px"
            >
              and retain more users. Trusted by over 4,000 startups.
            </Text>
          </Flex>
        </Flex>
        <Flex mt="5rem" gap="5rem" justifyContent={'center'} alignItems="center" direction={{base:'column',md:'row'}} flexWrap='wrap'   >
            <Flex gap=".5rem" alignItems="center" direction="column" padding={'5px'}>
              <img src={message} width="48px" height="48px" />
              <Text
                color="rgba(16, 24, 40, 1)"
                fontFamliy="Inter"
                fontSize="20px"
                fontWeight="500"
                lineHeight="30px"
              >
                Content based routing
              </Text>
              <Flex
                color="rgba(102, 112, 133, 1)"
                fontFamily="Inter"
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                textAlign="center"
                direction="column"
              >
                <Text>Effortlessly route messages based on content</Text>
                <Text>for tailored messaging experiences</Text>
              </Flex>
            </Flex>
            <Flex gap=".5rem" alignItems="center" direction="column">
              <img src={thunderbolt} width="48px" height="48px" />
              <Text
                color="rgba(16, 24, 40, 1)"
                fontFamliy="Inter"
                fontSize="20px"
                fontWeight="500"
                lineHeight="30px"
              >
                Number Specific Routing
              </Text>
              <Flex
                color="rgba(102, 112, 133, 1)"
                fontFamily="Inter"
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                textAlign="center"
                direction="column"
              >
                <Text>Optimize message delivery with precise</Text>
                <Text>routing based on recipient numbers</Text>
              </Flex>
            </Flex>
            <Flex gap=".5rem" alignItems="center" direction="column">
              <img src={bar} width="48px" height="48px" />
              <Text
                color="rgba(16, 24, 40, 1)"
                fontFamliy="Inter"
                fontSize="20px"
                fontWeight="500"
                lineHeight="30px"
              >
                Bulk Message Scheduling
              </Text>
              <Flex
                color="rgba(102, 112, 133, 1)"
                fontFamily="Inter"
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                textAlign="center"
                direction="column"
              >
                <Text>Plan and schedule large-scale messaging</Text>
                <Text>campaigns for maximum impact</Text>
              </Flex>
            {/* </Flex> */}
          </Flex>

          {/* <Flex gap="5rem" align="center" border='2px solid yellow'> */}
            <Flex gap=".5rem" alignItems="center" direction="column">
              <img src={happyface} width="48px" height="48px" />
              <Text
                color="rgba(16, 24, 40, 1)"
                fontFamliy="Inter"
                fontSize="20px"
                fontWeight="500"
                lineHeight="30px"
              >
                AI-Powered Firewall
              </Text>
              <Flex
                color="rgba(102, 112, 133, 1)"
                fontFamily="Inter"
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                textAlign="center"
                direction="column"
              >
                <Text>Enhance security with our AI-driven firewall, </Text>
                <Text>ensuring safe messaging operations..</Text>
              </Flex>
            </Flex>
            <Flex gap=".5rem" alignItems="center" direction="column">
              <img src={command} width="48px" height="48px" />
              <Text
                color="rgba(16, 24, 40, 1)"
                fontFamliy="Inter"
                fontSize="20px"
                fontWeight="500"
                lineHeight="30px"
              >
                Smart Invoice Generation
              </Text>
              <Flex
                color="rgba(102, 112, 133, 1)"
                fontFamily="Inter"
                fontWeight="400"
                fontSize="16px"
                lineHeight="24px"
                textAlign="center"
                direction="column"
              >
                <Text>Streamline your accounting with automated</Text>
                <Text>invoice generation and management</Text>
              </Flex>
            </Flex>
          {/* </Flex> */}
        </Flex>
        <Flex
          mt="5rem"
          direction={{sm:'row',md:'row'}}
          justifyContent="space-evenly"
          
          h="563px"
          bg="linear-gradient(265.57deg, #DBD3FF 0%, rgba(216, 227, 252, 0.41) 100%)"
          padding={'2rem'}
          
        >
          {/* <Flex gap="2rem" textAlign="left" direction="column" border={'2px solid'} > */}
            
            <Flex style={smallTextStyle} direction={{base:'column',md:'row'}} justifyContent={{md:'space-evenly'}} w={'60vw'} >
            
              <Flex direction={'column'} justify={'space-evenly'}>
              <Text mb={'1rem'}  style={bigTextStyle}>Try SMS Flash Today</Text>
             <Text>
                Experience the power of SMS Flash firsthand. Request a demo
              <br/>
              
                and discover how our SMPP Switch can transform your A2P
              <br/>
              messaging infrastructure.</Text>
              <Button
              onClick={onOpen}
              marginTop="2rem"
              fontFamily="Roboto"
              fontWeight="700"
              fontSize="16px"
              lineHeight="24px"
              color="rgba(255, 255, 255, 1)"
              bg="linear-gradient(84.6deg, #390EF7 0%, #9244F4 100.09%)"
              padding="16px, 40px, 16px, 40px"
              borderRadius="8px"
              w="174px"
              h="56px"
            
              _hover={{
                bg: "linear-gradient(84.6deg, #390EF7 0%, #9244F4 100.09%)",
              }}
            >
              Book a demo
            </Button>
              </Flex>
            
           
            </Flex>
            {/* <Flex border={'2px solid green'}> */}
           
          {/* </Flex> */}
         
          {/* </Flex> */}
        <Flex align={'center'}>
        <img src={chart} style={{height:'250px'}}/>
        </Flex>
            
        </Flex>

        <ExploreServices />
      </Flex>
      <Review />
      <Faq />
      <Flex mb="3rem" justifyContent="center">
        <Flex
          justifyContent="center"
          bg="linear-gradient(148.13deg, rgba(57, 14, 247, 0.08) 16.69%, rgba(146, 68, 244, 0.08) 84.78%)"
          h="300px"
          w="85%"
          borderRadius="16px"
        >
          <Flex mt="2rem" alignItems="center" gap="1rem" direction="column">
            <img src={groupIcon} width="120px" height="56px" />
            <Text
              fontFamily="Inter"
              fontWeight="500"
              fontSize="20px"
              lineHeight="30px"
              color="rgba(16, 24, 40, 1)"
            >
              Still have questions?
            </Text>
            <Text
              fontFamily="Inter"
              fontWeight="400"
              fontSize="16px"
              lineHeight="28px"
              color="rgba(102, 112, 133, 1)"
            >
              Can’t find the answer you’re looking for? Please chat to our
              friendly team.
            </Text>
            <Button
              borderRadius="8px"
              w="130px"
              h="44px"
              bg="linear-gradient(180deg, #390EF7 0%, #8C33FC 100%)"
              color="rgba(255, 255, 255, 1)"
              fontFamily="Inter"
              fontWeight="600"
              fontSize="16px"
              lineHeight="24px"
              _hover={{
                bg: "linear-gradient(180deg, #390EF7 0%, #8C33FC 100%)",
              }}
            >
              Get in touch
            </Button>
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </Box>
  );
}

export default LandingPage;
